/**
 * Imports libraries of settings, mixins, functions, etc. that should be available
 * to all stylesheets. MUST NOT OUTPUT STYLES.
 *
 * @author    <corey@chatmandesign.com>
 * @since     3.0.0
 */
/**
 * Breakpoint range variables for the lock mixin.
 * Convert the breakpoints map into range variables like we had in ZF5. The
 * magic number at the end is the difference between the breakpoint we define in
 * $breakpoints and the actual max-width value spit out by the breakpoints()
 * mixin when we give it an "only" argument (to avoid overlap. See the ZF5 media
 *  query range variables for reference.)
 *
 * $small-range is a special snowflake. The whole purpose of the lock mixin is
 * to let you set a property to a fixed value up to a certain breakpoint, then
 * have that value increase in a linear fashion until you hit another
 * breakpoint, and then keep the value fixed again for the rest of the way. The
 * way the breakpoints are defined, the small range would be 0px - 640px. But
 * we're not planning on supporting devices smaller than 320px, so it's far more
 * useful for the small range to be 320px to 640px. That way, the $min-value you
 * pass to the lock mixin is anchored to the smallest device width we support,
 * and the $max-value is anchored to the breakpoint between small and medium
 * ranges.
 *
 * @author   <dustin@chatmandesign.com>
 */
/**
 * Sass Mixins
 *
 * @package   seedyjoints
 * @since     1.0
 */
/**
 * Extend element's background to left and right edges of viewport.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 *
 * @param string $position  Any valid CSS position value, or NULL/FALSE to not set
 *                          position; but note the element must create a positioning
 *                          context for the bleed effect to work. Default: relative.
 * @param string $direction Direction the bleed will extend in -- left, right, both.
 *                          Deafult: both.
 */
/**
 * Lock (Fluid Type Mixin -- CD Remix)
 * Created 10/11/16 @ 02:19:30 PM
 *
 * Original from here:
 * http://madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * This is a modified version of the above mixin that takes a single SCSS list
 * instead of two variables for min and max VW. This makes it easier to use with
 * the existing Foundation range variables in _settings.scss. You can of course
 * still pass a plain list e.g. "(20rem, 40rem)" as the argument for the range
 * parameter if you need a custom range.
 *
 * @param      $properties A space separated list of CSS properties
 * @param      $vw-range A SCSS list defining a viewport width range like
 *             "(20em, 40em)".
 * @param      $min-value A minimum property value in px/em/rem
 * @param      $max-value A maximum property value in px/em/rem
 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
 */
/**
 * List items inline, separated by pipes by default.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */
/**
 * Transition property with vendor prefixes (DEPRECATED)
 *
 * DEPRECATED now that we use autoprefixer. Just use the standard CSS property.
 *
 * I totally rewrote this to be cleaner and easier to use. You'll need to be using
 * Sass 3.2+ for these to work. Thanks to @anthonyshort for the inspiration on these.
 *
 * USAGE: @include transition(<property> <time> <easing>);
 */
/**
 * CSS3 Gradients with vendor prefixes
 *
 * Be careful with these since they can really slow down your CSS. Don't overdo it.
 */
/* @include css-gradient(#dfdfdf,#f8f8f8); */
/**
 * Foundation for Sites
 * Version 6.6.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.cd-credit {
  display: block;
  position: relative;
  width: 4rem;
  opacity: 0.4;
  transition: opacity 3s ease-out;
  padding: 1rem;
  margin: 2rem auto 0;
}
.cd-credit:before, .cd-credit:after {
  display: block;
  position: absolute;
  top: 50%;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-28%);
  transition: opacity 3s ease-out;
  font-size: 0.75rem;
  font-weight: 900;
  letter-spacing: 0.0625rem;
  line-height: 1;
  white-space: nowrap;
}
.cd-credit:before {
  right: 100%;
  content: "POWERED BY";
}
.cd-credit:after {
  left: 100%;
  content: "CHATMAN DESIGN";
}
.cd-credit > svg {
  fill: #8a8a8a;
}
.cd-credit:hover, .cd-credit:focus {
  opacity: 1;
  transition: opacity 0.8s ease-in-out;
}
.cd-credit:hover:before, .cd-credit:hover:after, .cd-credit:focus:before, .cd-credit:focus:after {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.8s ease-in-out;
  color: #8a8a8a;
}
@media print, screen and (min-width: 40em) {
  .cd-credit {
    margin-top: 4rem;
  }
}