@import '../../global/styles/lib';

.cd-credit {
    $text-offset: 100%;
    $transition-on: opacity .8s ease-in-out;
    $transition-off: opacity 3s ease-out;

    display: block;
    position: relative;
    width: 4rem;
    opacity: .4;
    transition: $transition-off;
    padding: 1rem;
    margin: 2rem auto 0;

    &:before,
    &:after {
        display: block;
        position: absolute;
        top: 50%;
        opacity: 0;
        pointer-events: none;
        transform: translateY(-28%);
        transition: $transition-off;
        font-size: .75rem;
        font-weight: 900;
        letter-spacing: rem-calc(1);
        line-height: 1;
        white-space: nowrap;
    }
    &:before {
        right: $text-offset;
        content: 'POWERED BY';
    }
    &:after {
        left: $text-offset;
        content: 'CHATMAN DESIGN';
    }

    > svg {
        fill: $dark-gray;
    }

    &:hover,
    &:focus {
        opacity: 1;
        transition: $transition-on;

        &:before,
        &:after {
            opacity: 1;
            pointer-events: auto;
            transition: $transition-on;
            color: $dark-gray;
        }

    } // &:hover, &:focus

    @include breakpoint(medium up) {
        margin-top: 4rem;
    } // large up

} // .cd-credit